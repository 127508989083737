import PropTypes from 'prop-types';
import Chevron from 'svg/chevron-right.svg';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const jsonLD = links => {
  const obj = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: links.map(({ label, href }, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: label,
      item: `${process.env.ENVIRONMENT_URL}${href}`,
    })),
  };

  return JSON.stringify(obj);
};

const Breadcrumbs = ({ links = [] }) => {
  const { t } = useTranslation();

  if (!links.length) return null;

  const homeLink = {
    label: t('homepage.pageTitle'),
    href: '/',
  };

  return (
    <div className="c-breadcrumbs">
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: jsonLD([homeLink, ...links]) }}
      />
      <ol className="c-breadcrumbs__list">
        {[homeLink, ...links].map(({ label, href }, index, array) => (
          <li className="c-breadcrumbs__crumb" key={label}>
            {index + 1 === array.length && (
              <span className="c-breadcrumbs__span">{label}</span>
            )}
            {index < array.length - 1 && (
              <>
                <Link href={href} className="c-breadcrumbs__link">
                  {label}
                </Link>
                <Chevron className="c-breadcrumbs__chevron" />
              </>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
};

export default Breadcrumbs;
