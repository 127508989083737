import PropTypes from 'prop-types';
import Image from 'next/legacy/image';

const CompanyLogo = ({ company, extraClasses, priority }) => {
  const imgSrc = company?.logo?.contentUrl;
  if (imgSrc) {
    return (
      <div className={`c-company-logo ${extraClasses || ''}`.trim()}>
        <Image
          src={imgSrc}
          alt={company.name || company.company}
          layout="fill"
          className="c-company-logo__image"
          sizes="280px"
          priority={priority}
        />
      </div>
    );
  }
  return null;
};

CompanyLogo.propTypes = {
  company: PropTypes.object.isRequired,
  extraClasses: PropTypes.string,
  priority: PropTypes.bool,
};

export default CompanyLogo;
